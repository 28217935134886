<template>
    <form @submit.prevent="onSubmit">
        <h3 class="title">
            Crea una Cuenta
        </h3>
        <div class="row">
        <div class="col-md-6">
            <div class="input-box">
            <input type="text" v-model="name" name="name" placeholder="Nombre Completo" required />
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-box">
            <input type="email" v-model="email" name="email" placeholder="Correo Electrónico" required />
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-box">
            <input type="password" v-model="password" name="password" placeholder="Contraseña" required />
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-box">
            <input type="password" v-model="password_confirmation" placeholder="Confirmar contraseña" required />
            </div>
        </div>
        <div class="col-lg-12">
            <div class="input-box">
                <button class="main-btn">REGISTRARME</button>
            </div>
        </div>
        </div>
    </form>        
</template>

<script>
import { http } from "../../axios";

export default {
    data: () => ({
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        errorMessage: null
    }),
    methods:{

        onSubmit(){
            
            http
            .post("auth/register", {
                username: this.email,
                name: this.name,
                email: this.email,
                password: this.password,
                c_password: this.password_confirmation,
            })
            .then((response) => {
                console.log('response',response)

                if(response.data.status){
                    this.finalizado = true;
                }else{
                    this.errorMessage = response.data.message;
                }
            })
            .catch((error) => {
                console.log(error);
                this.errorMessage = error;
                this.loadingRecintos = false;
            });
        }
    }
};
</script>

<style></style>
