<template>
    <section class="modal-buscador" v-if="$store.state.buscadorModal">
        <div class="container">
            <h2 class="d-flex p-2 bg-secondary text-white relative">
                <span>
                    Buscar Noticias Anteriores
                </span>
                <button class="absolute ml-auto cursor-pointer rounded-lg" @click="$store.dispatch('toggleBuscador')">
                    <i class="fal fa-times-circle"></i>
                </button>
            </h2>
            <div class="text-center">
                <div class="px-4">
                    <input  type="text"
                            v-model="searchQuery"
                            @input="buscarResulados"
                            placeholder="Ejemplo: Asambleistas (mínimo 2 letras)"
                            class="w-100 rounded-lg p-3"
                            autofocus
                            name="searchQuery"
                            >
                </div>
                <div>
                    <div v-for="resultado in resultados" :key="resultado.id" class="bg-white px-2 shadow-lg rounded-lg">
                        
                        <h4 class="cursor-pointer" @click="goToResultado(resultado)">
                            {{ resultado.titulo }}
                        </h4>
                        <h6 class="text-left">{{ resultado.subtitulo }} <span class="ml-10 text-rojo">{{ formatDate(resultado.fecha) }}</span></h6>
                    </div>
                    <div class="text-white h3 p-3 font-italic" v-if="resultados.length == 0 && searchQuery">Sin resultados para <strong class="text-rojo">{{ searchQuery }}</strong></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { http } from "../../../axios";
import moment from 'moment';
import router from './../../../router/index'

export default {
    data: () => ({
        resultados: [],
        loadingResultados: false,
        errorMessage: null,
        searchQuery: null
    }),
    methods:{
        
        buscarResulados() {
            console.log('searchQuery',this.searchQuery)
            this.loadingResultados = true
            if(this.searchQuery.length > 1){
                http
                    .get("web/buscador?q="+this.searchQuery, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        this.resultados = response.data.items;
                        this.loadingResultados = false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.errorMessage = error;
                        this.loadingResultados = false
                    });
            }else{
                this.resultados = [];
            }

        },
        goToResultado(resultado){
            this.$store.dispatch('toggleBuscador')
            router.replace({ name: 'PostThree', params: { slug: resultado.slug} })
        }
    },
    setup() {
        const formatDate = (item) =>{
            moment.locale('es')
            let date = moment(item).format("MMMM D, YYYY");
            return date
        }
        return {
            formatDate,
        }
    }
}
</script>
<style scoped>
.modal-buscador {
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
}
.text-rojo{
    color: #f41211;
    font-style: italic;
}
</style>
