<template>
    <div>
        <div class="about-author-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="about-author-content">
                            <header>
                                <h2 class="text-center">
                                   Código Deontológico de LaVeci.com <br>
                                   <small>
                                       Código de Ética
                                   </small>
                                </h2>
                                <p class="text-center">
                                    <i>
                                        Este documento equivale al Código de Ética que requiere el Consejo de Comunicación de la República del Ecuador
                                    </i>
                                </p>
                            </header>
                            <div>
                                <h5>1. Introducción</h5>
                                <p>
                                  Este Código Deontológico aplica a todos los miembros de LaVeci.com, incluyendo empleados, colaboradores externos y freelancers. LaVeci.com se compromete a mantener la más alta integridad en su periodismo y respeto por los derechos humanos, operando independientemente de influencias externas políticas, económicas o religiosas.
                                </p>
                                
                                <h5>2. Aplicación Universal</h5>
                                <p>
                                    El presente código es aplicable a todas las plataformas de LaVeci.com: impresa, digital y redes sociales, asegurando consistencia ética en todas las formas de entrega de contenido.
                                </p>

                                <h5>3. Uso de Inteligencia Artificial y Tecnología</h5>
                                <p>
                                    LaVeci.com emplea tecnología de inteligencia artificial y otras herramientas tecnológicas para la recolección de datos, generación de información y automatización de publicaciones. A pesar del uso de tecnología avanzada, es imperativo que una persona autorizada por LaVeci.com revise y autorice el contenido final antes de su publicación o difusión, garantizando así la precisión y la adecuación del contenido a los estándares éticos del medio.
                                </p>

                                <h5>4. Responsabilidad Editorial</h5>
                                <p>
                                    La responsabilidad editorial y la autoría de todo contenido publicado recaen en el medio según el grado de implicación de cada miembro. Ningún contenido será publicado sin la debida verificación y autorización por parte de las instancias editoriales correspondientes de LaVeci.com.
                                </p>
                                
                                <h5>5. Imparcialidad y Pluralismo</h5>
                                <p>
                                    Nos comprometemos a tratar todos los temas con imparcialidad, sin excluir o favorecer ninguna opinión basada en clase social, creencia política o religiosa. Se reflejarán todas las corrientes de opinión pertinentes, siempre que no infrinjan las leyes.
                                </p>

                                <h5>6. Intimidad y Privacidad</h5>
                                <p>
                                    Respetamos la vida privada de las personas y solo divulgaremos información de personajes públicos cuando prevalezca un claro interés público. La protección de la intimidad será primordial salvo en circunstancias donde el interés público lo justifique explícitamente.
                                </p>

                                <h5>7. Derecho a la Réplica y Rectificaciones</h5>
                                <p>
                                    Todas las solicitudes de réplica o rectificación deben enviarse a miguelcabreratobar@gmail.com. Cada solicitud será evaluada cuidadosamente, y se realizarán rectificaciones cuando se determine que la información publicada es inexacta y el reclamo válido. Las rectificaciones se publicarán en las mismas condiciones de día, tamaño y hora que la publicación original, garantizando así la misma exposición que tuvo la nota incorrecta.
                                </p>

                                <h5>8. Protección de Fuentes y Personas Vulnerables</h5>
                                <p>
                                    La protección de fuentes, especialmente en casos sensibles, es fundamental para nuestra operación. Además, prestaremos especial atención a cómo se informa sobre niños, adolescentes y otros grupos vulnerables, asegurando que su representación en nuestras publicaciones sea respetuosa y legal.
                                </p>

                                <h5>9. Publicidad y Contenido Patrocinado</h5>
                                <p>Todo contenido patrocinado o publicitario será claramente identificado como tal, separando la editorial de la influencia comercial para mantener nuestra integridad y credibilidad.</p>

                                <h5>10. Cumplimiento del Código</h5>
                                <p>Todos los colaboradores de LaVeci.com deben comprometerse a cumplir este código y recibirán formación sobre sus principios y aplicación. Las infracciones serán tratadas con seriedad y pueden resultar en sanciones o la terminación de la colaboración.</p>

                                <h5>11. Publicación del Código</h5>

                                <p>Dando cumplimiento al art. 9 del reglamento a la ley de comunicación del 2023, el código de ética y buzón ciudadano deben publicarse en un enlace permanente en la web del medio. Por lo que se publicará en el siguiente link: <a href="https://laveci.com/codigoetica">https://laveci.com/codigoetica</a> y <a href="https://laveci.com/buzonciudadano">https://laveci.com/buzonciudadano</a> respectivamente.</p>

                                <div>
                                    <a class="main-btn" href="/uploads/Codigo_de_Etica_de_LaVeci.pdf" download="">Descargar Documento</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="go-top-area">
            <div class="go-top-wrap">
            <div class="go-top-btn-wrap">
                <a href="#" class="go-top go-top-btn d-block">
                <i class="fa fa-angle-double-up"></i>
                <i class="fa fa-angle-double-up"></i>
                </a>
            </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>

  export default {
    components: {

    },
    data: () => ({
      sidebar: false,
    }),
    created() {
      document.addEventListener("scroll", this.topToBottom);
    },
    methods: {
      toggleSidebar() {
        this.sidebar = !this.sidebar;
      },
      topToBottom() {
        const result = document.querySelector(".go-top");
        if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
        ) {
          result.classList.add("active");
        } else {
          result.classList.remove("active");
        }
      },
    },
  };
  </script>
  
  <style></style>
  