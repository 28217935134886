<template>
    <v-chart class="chart" :option="option" autoresize />
  </template>
  
  <script>
    import { use } from 'echarts/core';
  import { CanvasRenderer } from 'echarts/renderers';
  import { PieChart } from 'echarts/charts';
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
  } from 'echarts/components';

  import VChart, { THEME_KEY } from 'vue-echarts';
  import { ref, provide } from 'vue';
  
      
    export default {
        components:{
            VChart,
        },
        props:{
            encuesta:{
                type: Object,
                required: true
            }
        },
        setup(props){

            use([
                CanvasRenderer,
                PieChart,
                TitleComponent,
                TooltipComponent,
                LegendComponent,
            ]);

            provide(THEME_KEY, 'dark');
            
            let opciones = props.encuesta.opciones_ordenadas.map(o => {
                return  o.nombre
            })

            let datos = props.encuesta.opciones_ordenadas.map(o => {
                return {
                    value: o.respuestas_count,
                    name: o.nombre,
                }
            })

            console.log('opciones_ordenadas', opciones);

            const option = ref({
              title: {
                text: props.encuesta.nombre,
                left: 'center',
              },
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)',
              },
              legend: {
                orient: 'vertical',
                left: 'left',
                data: opciones,
              },
              series: [
                {
                  name: props.encuesta.nombre,
                  type: 'pie',
                  radius: '55%',
                  center: ['50%', '60%'],
                  data: datos,
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                  },
                },
              ],
            });


            return {
                option
            }
        }
  }
  </script>
  
  <style scoped>
  .chart {
    height: 100vh;
  }
  </style>
  