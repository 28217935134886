<template>
  <div>
    <!-- <category-layout> -->
      <section v-if="noticias.length" class="about-item-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="about-author-content pt-15">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item" ><router-link :to="{ name: 'Home'}">Inicio</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      "internacional"
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="about-tab-btn mt-40">
                <div class="archive-btn">
                  <ul>
                    <li>
                      <span v-if="vista == 'internacional'">!Noticias Internacionales 😲!</span>
                      <span v-else-if="vista == 'nacional'">!Noticias Nacionales 😲!</span>
                      <span v-else >!Noticias <span class="text-capitalize">{{ vista }} </span> 😲!</span>
                    </li>
                  </ul>
                </div>
                <div class="about-post-items">
                  <div class="row">
                    <div v-for="noticia in noticias" :key="noticia.id" class="col-md-6">
                      <div class="feature-news-item">
                        <div class="feature-news-thumb">
                            <router-link :to="{ name: 'PostThree', params: { slug: noticia.slug} }">
                                <img
                                    :src="noticia.imagenURLMd2"
                                    alt="feature"
                                />
                            </router-link>
                          <div class="meta-categores">
                            <router-link :to="{ name: 'Features', params: { slug: noticia.firstCategoria.slug} }">
                                <span>{{ noticia.firstCategoria.titulo }}</span>
                            </router-link>
                          </div>
                        </div>
                        <div class="feature-news-content">
                            <router-link :to="{ name: 'PostThree', params: { slug: noticia.slug} }">
                                {{ noticia.titulo }}
                            </router-link>   
                        </div>
                      </div>
                    </div>

                    <!-- paginación -->
                    <div class="col-lg-12">
                        <div class="pagination-item">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li v-for="pagina in totalPaginas" :key=pagina class="page-item"  :class="paginaActual == pagina ? 'active' : ''" ><a @click="paginado(pagina)" class="page-link"> {{ pagina }}</a></li>
                                    <!-- <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true"><i class="fas fa-caret-right"></i></span>
                                        </a>
                                    </li> -->
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12">
                      <div class="bussiness-btn mt-30">
                        <a class="main-btn main-btn-2" href="#">See more</a>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <style-one c:lass="mt-40" role="sidebar" :signup="true" :trendingShortPost="false" :trendingBigPost="false" :ad="true" :categorias-destacadas="categoriasDestacadas" :categoria-destacada-tres="categoriaDestacadaTres" :articulos-vistos="articulosVistos"/>
            </div>
          </div>
        </div>
      </section>
      <section v-else  class="about-item-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="about-author-content pt-15">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item" ><router-link :to="{ name: 'Home'}">Inicio</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      "internacional"
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="about-tab-btn mt-40">
                <div class="archive-btn">
                  <ul>
                    <li>
                      <span v-if="vista == 'internacional'">!Noticias Internacionales 😲!</span>
                      <span v-else-if="vista == 'nacional'">!Noticias Nacionales 😲!</span>
                      <span v-else >!Noticias <span class="text-capitalize">{{ vista }} </span> 😲!</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="alert alert-primary" role="alert">
                Al momento no existen noticias!
              </div>
          </div>
        </div>
      </div>


      

      </section>
    <!-- </category-layout> -->
  </div>
</template>

<script>
import StyleOne from "../Utility/Sidebar/StyleOne.vue";
import Posts from "../Data/TrendingHomeThree";


import { http } from "../../axios"


export default {
  components: { StyleOne },
  data: () => ({
    posts: Posts.data,
    noticias: [],

    
    totalItems: null,
    paginaActual: null,
    totalPaginas: null,


    categoriasDestacadas: [],
    categoriaDestacadaTres: {},

    vista: null,

    articulosVistos: [],

  }),

  watch: {
    '$route.path': {
        handler: function() {
            this.vista = this.$route.params.slug;

            this.fetchNoticiasDepartamento();
            this.fetchCategoriaDestacadaTres();
            this.fetchCategoriasDestacadas();
        },
        // deep: true,
        // immediate: true
    }

  },

  created() {
    this.vista = this.$route.params.slug;

    this.fetchNoticiasDepartamento();
    this.fetchCategoriaDestacadaTres();
    this.fetchCategoriasDestacadas();

   this.fetchArticulosMasVistos();

   
  },
  methods: {

    paginado (pagina) {
      if(pagina != this.paginaActual ){
        this.fetchNoticiasDepartamento(pagina);

      }
    },

    fetchNoticiasDepartamento(pagina){
        http
        .get("web/noticias-departamento",{
           
            params:{  page: pagina, slug: this.$route.params.slug},
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            if(response.status){
                this.noticias = response.data.items;
                
                this.totalItems = response.data.total;
                this.paginaActual = response.data.current_page;
                this.totalPaginas = response.data.last_page;
            }else{
                console.log('error noticias departamento',response)
            }
        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },

    fetchCategoriasDestacadas(){
        http
        .get("web/categorias-destacadas",{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            this.categoriasDestacadas = response.data.items;
        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },

    fetchCategoriaDestacadaTres(){
          http
          .get("web/categoria-destacada-tres",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.categoriaDestacadaTres = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

      fetchArticulosMasVistos(){
          http
          .get("web/articulos-mas-vistos",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.articulosVistos = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

  }
};
</script>

<style></style>
