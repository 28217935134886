<template>
    <div
      :class="[
      'post_gallery_sidebar',
      darkClass && 'section' in darkClass ? darkClass.section : '',
      ]"
      v-if="selectedGallery != null"
    >
      <ul class="nav nav-pills" id="pills-tab" role="tablist">
          <li v-for="(categoria, index) in categoriasDestacadas" :key="index" class="nav-item" @click.prevent="selectGalleryTab(categoria.titulo)">
          <a
              :class="['nav-link', selectedGallery === categoria.titulo ? 'active' : '']"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              style="cursor: pointer;"
          >
              {{ categoria.titulo }}
          </a>
          </li>
      </ul>

      <div id="margin-tab" class="tab-content">
          <div
              v-for="(categoria, index) in categoriasDestacadas" :key="index"
              :class="[
              'tab-pane fade',
              selectedGallery === categoria.titulo ? 'show active' : '',
              ]"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
          >
              <div class="post_gallery_items">
              <template v-for="(data, index) in categoria.articulos">
                  <row-card
                  :class="[darkClass && 'item' in darkClass ? darkClass.item : '']"
                  :category="true"
                  :datas="data"
                  :key="index"
                  />
              </template>
              </div>
          </div>
      </div>
  
      <!-- <signup-style-two v-if="signup" /> -->
      <div v-if="trendingBigPost" class="trending-sidebar mt-40">
        <div class="section-title">
          <h3 class="title">Trending News</h3>
        </div>
        <div class="trending-sidebar-slider position-relative">
          <span
            @click.prevent="trendingNewsPrev"
            class="prev slick-arrow"
            style="display: block"
            ><i class="fal fa-angle-left"></i
          ></span>
          <slider :settings="trendingNews" ref="trendingNews">
            <template v-for="(data, index) in datas" >
              <divide-card stype="col" :datas="data" :key="index" />
            </template>
          </slider>
          <span
            @click.prevent="trendingNewsNext"
            class="next slick-arrow"
            style="display: block"
            ><i class="fal fa-angle-right"></i
          ></span>
        </div>
      </div>

      <div v-if="trendingShortPost" class="pt-2">
        <div class="section-title">
          <h3 class="title seccion-ultimas-noticias-categoria">Últimas Noticias</h3>
        </div>
        <div v-for="(data, index) in datas" :key="index" class="gallery_item">
          <div class="gallery_item_thumb">
            <a :href="'/noticia/'+data.slug">
                <img :src="data.imagenURLSm" alt="gallery" />
            </a>
            <!-- <div class="icon"><i class="fas fa-bolt"></i></div> -->
          </div>
          <div class="gallery_item_content">
            <div class="post-meta">
              <div class="meta-categories">
                <a :href="'/categoria/'+data.firstCategoria.slug" >{{ data.firstCategoria.titulo }}</a>
              </div>
              <div class="meta-date">
                <span>{{formatDate(data.fecha) }}</span>
              </div>
            </div>
            <h4 class="title">
              <a :href="'/noticia/'+data.slug">{{ data.titulo }}</a>
            </h4>
          </div>
        </div>

      </div>
      <div v-if="ad" class="sidebar-add">
            <!--====== Anuncios START ======-->
                <anuncios-banner :tipo-anuncio="'w2'"/>
            <!--====== Anuncios ENDS ======-->
      </div>

      <div  v-if="estadisticas" class="trending-right-sidebar">
        <FollowCount/>        
      </div>


      <share-post v-if="sharePost"  :articulos-vistos="articulosVistos"  class="mt-30" />

      
   

   
   
    </div>
  </template>
  
  <script>
  import Slider from "../../Helpers/Slider.vue";
  import SharePost from "../../Home/SharePost.vue";
  import DivideCard from "../Cards/DivideCard.vue";
  import RowCard from "../Cards/RowCard.vue";
  // import SignupStyleTwo from "../Signup/SignupStyleTwo.vue";
  import FollowCount from "../../Utility/FollowCount/index";
  import moment from 'moment';
  import AnunciosBanner from "../../../components/Utility/Anuncios/AnunciosBanner.vue";

  export default {
    components: { RowCard, DivideCard, Slider, FollowCount, AnunciosBanner, SharePost },
    props: {
      datas: {
        type: Array,
        default: () => [],
      },
      darkClass: {
        type: Object,
        default: () => {},
      },
      signup: {
        type: Boolean,
        default: true,
      },
      trendingBigPost: {
        type: Boolean,
        default: true,
      },
      trendingShortPost: {
        type: Boolean,
        default: true,
      },
      ad: {
        type: Boolean,
        default: true,
      },
      sharePost: {
        type: Boolean,
        default: true,
      },
      categoriasDestacadas: {
        type: Array,
        default: () => [],
      },
      categoriaDestacadaTres: {
        type: Object,
        default: () => {},
      },
      estadisticas: {
        type: Boolean,
        default: true,
      },

      articulosVistos: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
        selectedGallery: "La nota",
        trendingNews: {
        autoplay: true,
        slideToScroll: 1,
        slidesToShow: 1,
        arrows: false,
        dots: false,
      

      },
    }),
    created() {
      this.selectFirstCategory();
    },

    methods: {
      selectGalleryTab(value) {
        console.log(value);
        this.selectedGallery = value;
      },
      trendingNewsPrev() {
        this.$refs.trendingNews.prev();
      },
      trendingNewsNext() {
        this.$refs.trendingNews.next();
      },
      selectFirstCategory() {
        // if(this.categoriasDestacadas) {
        //   let categoria = this.categoriasDestacadas[0].titulo;
        //   console.log("categoria", categoria);
        //   this.selectGallery = categoria;
        //   console.log("this.selectGallery ",this.selectGallery );
        // }
      },

    
    },

    setup() {
    const formatDate = (item) =>{
			moment.locale('es')
			let date = moment(item).format("MMMM D, YYYY");
			return date
		}
    return {
      formatDate,

    }
  }

  };
  </script>
  
  <style></style>
  