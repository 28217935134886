<template>
  <!--====== OFFCANVAS MENU PART START ======-->
  <div>
    <div
      class="off_canvars_overlay"
      :class="[sidebar ? 'active' : '']"
      @click.prevent="hideSidebar"
    ></div>
    <div class="offcanvas_menu">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div
              class="offcanvas_menu_wrapper"
              :class="[sidebar ? 'active' : '']"
              style="overflow-y: scroll; overflow-x: hidden"
            >
              <div class="canvas_close">
                <a href="javascript:void(0)" @click="hideSidebar"
                  ><i class="fa fa-times"></i
                ></a>
              </div>
              <div class="offcanvas-brand text-center mb-40">
                <img src="@/assets/images/logo.png" alt="appie" />
              </div>
              <div id="menu" :style="heightPro" class="text-left">
                <ul class="offcanvas_main_menu list-unstyled">
                  <li
                    v-for="(item, index) in menuItems"
                    :key="index"
                    class="menu-item-has-children active"
                    :class="[menuOpen === item.linkText ? 'menu-open' : '']"
                  >
                    
                    <span
                      v-if="item.submenu && item.submenu.length > 0 && nasted"
                      class="menu-expand"
                      ><i class="fa fa-angle-down"></i
                    ></span>
                    <router-link
                      style="text-transform: capitalize"
                      v-if="item.link"
                      :to="item.link"
                    >
                      <span @click="hideSidebar()" v-if="item.linkText == 'INICIO'" :class="vista == '/' ? 'menu_active' : ''" >
                          {{item.linkText}}
                      </span>
                      <span  @click="hideSidebar()" v-else-if="item.linkText == 'NOTICIAS'" :class="vista == '/noticias' ? 'menu_active' : ''" >
                          {{item.linkText}}
                      </span>
                      <span @click="hideSidebar()" v-else-if="item.linkText == 'CATEGORÍAS'" :class="vista == '/categorias' ? 'menu_active' : ''" >
                          {{item.linkText}}
                      </span>

                      </router-link>
                    <a v-else href="#" @click.prevent="show(item.linkText)">
                      {{ item.linkText }}</a
                    >
                    <ul
                      v-if="item.submenu && nasted"
                      :id="item.linkText"
                      class="sidebar-sub-menu list-unstyled ml-3"
                      :class="[
                        menuOpen === item.linkText ? 'expend_menu_item' : '',
                      ]"
                    >
                      <li v-for="(subItem, i) in item.submenu" :key="i">
                        <router-link :to="subItem.link ? subItem.link : '#'">{{
                          subItem.linkText
                        }}</router-link>
                      </li>
                    </ul>
                  </li>

                  <li v-for="(item, index) in $store.state.menuDepartamentosDest" :key="index.id">
                    <router-link class="btn_menu" :to="{ name: 'Sports', params: { slug: item.slug} }">
                        <span @click="hideSidebar()"  :class="vista == '/departamentos/'+item.nombre.toLowerCase()  ? 'menu_active' : ''" >
                            {{item.nombre}}
                        </span>
                    </router-link>
                  </li>

                  <li>
                    <span @click="menuOpenProvicias()" class="departamentos" >
                      Provincias
                    </span>
                    <i class="fal fa-angle-down"></i>
                    <ul 
                      class="sidebar-sub-menu list-unstyled ml-3 mt-3"  
                      :class="[
                        menuOpen === 'Provincias' ? 'expend_menu_item' : '',
                      ]" 
                     >
                      <li v-for="(lvlTwo, index) in $store.state.menuDepartamentos" :key="index.id">
                        <router-link :to="{ name: 'Sports', params: { slug: lvlTwo.slug} }">
                          <!-- {{ lvlTwo.nombre }} -->
                            <span @click="hideSidebar()" :class="vista == '/departamentos/'+lvlTwo.nombre.toLowerCase()  ? 'menu_active' : ''" >
                                {{lvlTwo.nombre}}
                            </span>
                        </router-link>
                      </li>
                    </ul>
                  </li>



                </ul>
              </div>
              <div class="offcanvas-social">
                <ul class="text-center list-unstyled">
                  <li>
                    <a :href="$store.state.configs.facebook_url" ><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a :href="$store.state.configs.instagram_url" ><i class="fab fa-instagram"></i></a>
                  </li>
                  <li>
                    <a :href="$store.state.configs.twitter_url" ><i class="fab fa-whatsapp"></i></a>
                  </li>
                </ul>
              </div>
              <!-- <div class="footer-widget-info">
                <ul>
                  <li>
                    <a href="#"
                      ><i class="fal fa-envelope"></i> support@appie.com</a
                    >
                  </li>
                  <li>
                    <a href="#"
                      ><i class="fal fa-phone"></i> +(642) 342 762 44</a
                    >
                  </li>
                  <li>
                    <a href="#"
                      ><i class="fal fa-map-marker-alt"></i> 442 Belle Terre St
                      Floor 7, San Francisco, AV 4206</a
                    >
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====== OFFCANVAS MENU PART ENDS ======-->
</template>

<script>
import Navs from "../Data/NavItems";

export default {
  props: {
    sidebar: {
      type: Boolean,
      required: true,
    },
    nasted: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menuOpen: null,
      subMenuHeight: null,
      menuItems: Navs.data,
      vista: null,

    };
  },

  created() {

   this.vista = this.$route.path;
 },

 watch: {
  '$route.path': {
      handler: function() {
          console.log("menu",this.$route.path);
          this.vista = this.$route.path;
          console.log("menu",this.vista);
      },
      // deep: true,
      // immediate: true
    }
  },




  computed: {
    heightPro() {
      return {
        "--height": this.subMenuHeight,
      };
    },
  },
  methods: {
    hideSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
    show(value) {
      if (value !== this.menuOpen) {
        this.menuOpen = value;
      } else {
        this.menuOpen = null;
      }
      const getListItem = document.querySelectorAll(`#${value} li`).length;
      this.subMenuHeight = 43 * getListItem + "px";
    },

      menuOpenProvicias(){
        if(this.menuOpen == null ){
          this.menuOpen = 'Provincias';
         

        }else{
          this.menuOpen = null;
        

        }

      },

  




  },
};
</script>

<style>
.sidebar-sub-menu {
  padding-left: 20px;
  overflow: hidden;
  height: 0px;
}
.sidebar-sub-menu {
  transition: all linear 0.65s;
}
.sidebar-sub-menu.expend_menu_item {
  transition: all linear 0.65s;
}
.expend_menu_item.sidebar-sub-menu {
  height: var(--height);
}
</style>
