<template>
  <section class="latest-news-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <h3 v-if="titulo == 'Otras Categorías'" class="title text-center seccion-otras-categorias">{{ titulo  }}</h3>
            <h3 v-else class="title seccion-otras-noticias">{{ titulo  }}</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-for="(data, index) in posts"
          :key="index"
          class="col-lg-4"
        >
          <divide-card :stype=stype :datas="data" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DivideCard from "../Cards/DivideCard.vue";
export default {
  components: { DivideCard },
  props: {
    posts: {
      type: Array,
    },
    titulo: {
        type: String,
    },
    stype: {
        type: String,
    },
  },
};
</script>

<style></style>
