<template>
    <div>

        <section class="about-item-area pb-80">
            <div class="container">
                <div class="row justify-content-center" v-if="encuestas">
                    <div class="col-lg-8">
                        <div class="about-tab-btn mt-40">
                            <div class="archive-btn">
                                <ul>
                                    <li>¡Encuestas LA VECI!</li>
                                </ul>
                            </div>
                            <div v-for="encuesta in encuestas" :key="encuesta.id" class="about-post-items">
                                <div class="row">
                                    <div class="col-lg-12">
                                    <div class="bussiness-post-item mb-40">
                                        <div class="bussiness-post-thumb">
                                        <router-link :to="{ name: 'Encuesta', params: { id: encuesta.id} }">
                                            <img
                                            :src="encuesta.imagenURL"
                                            :alt="encuesta.nombre"
                                            width="20%"
                                            />
                                        </router-link>
                                        </div>
                                        <div class="bussiness-post-content">
                                        <h3 class="title">
                                            <router-link :to="{ name: 'Encuesta', params: { id: encuesta.id} }">
                                            {{ encuesta.nombre }}
                                            </router-link>
                                        </h3>
                                        <!-- <div class="meta-date-link">
                                            <span>April 26, 2020</span>
                                            <ul>
                                            <li>
                                                <a href="#"><i class="fal fa-bookmark"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i class="fas fa-share"></i></a>
                                            </li>
                                            </ul>
                                        </div> -->
                                        <p v-if="encuesta.descripcion">
                                            {{ encuesta.descripcion }}
                                        </p>
                                        <router-link :to="{ name: 'Encuesta', params: { id: encuesta.id} }">
                                            <span class="badge" :class="encuesta.activa ? 'badge-success' : 'badge-danger'">{{ encuesta.activa ? 'Activa': 'Finalizada' }}</span> Ver Encuesta
                                            <img src="@/assets/images/arrow-2.svg" alt=""/>
                                        </router-link>
                                        </div>
                                    </div>
                                    </div>

                                    <!-- <div class="col-lg-12">
                                    <div class="bussiness-btn">
                                        <a class="main-btn main-btn-2" href="#">See more</a>
                                    </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>
<script>
import { http } from "../../axios";

export default {
    components: {
        
    },
    data: () => ({
        encuestas: [],
        seleccionado: null,
        finalizado: false
    }),

    created() {
        document.addEventListener("scroll", this.topToBottom);
        this.fetchEncuestas();
    },
    computed:{
        seleccionadoId(){
            return this.seleccionado ?  this.seleccionado.id : 0
        }
    },

    methods: {
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
        topToBottom() {
            const result = document.querySelector(".go-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.classList.add("active");
            } else {
                result.classList.remove("active");
            }
        },

        fetchEncuestas() {
            // this.loadingRecintos = false
            http
                .get("web/encuestas", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    this.encuestas = response.data.items;
                })
                .catch((error) => {
                    console.log(error);
                    this.errorMessage = error;
                    this.loadingRecintos = false;
                });
        },
    },
};
</script>
  
<style scoped>
/* play post */
.single-play-post-area .single-play-box {
    padding: 23px 12px !important;
}

.no_mostrar {
    display: none;
}
.opcion-encuesta{
    cursor: pointer!important;
}
.opcion-encuesta:hover{
    background-color: #ef9f1e;
}

.opcion-seleccionada{
    background-color: #f41211 !important;
}
</style>
  