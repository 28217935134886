<template>
  <div v-if="stype === 'row'" class="row">
    <div class="col-lg-6 col-md-6">
      <div class="business-post-thumb">
        <img :src="require(`@/assets/images/` + datas.image)" alt="feature" />
      </div>
    </div>
    <div class="col-lg-6 col-md-6">
      <div class="trending-news-item">
        <div class="trending-news-content">
          <div class="post-meta">
            <div class="meta-categories">
              <router-link to="/buisness">{{
                datas.category.name
              }}</router-link>
            </div>
            <div class="meta-date">
              <span>{{ datas.category.create_At }}</span>
            </div>
          </div>
          <h3 class="title">
            <router-link to="/posts/postOne">{{ datas.title }}</router-link>
          </h3>
          <p class="text">
            {{ datas.article }}
          </p>
          <a v-if="readMore" href="#">Read more</a>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="stype === 'row-style-2'" class="bussiness-post-item">
    <div class="bussiness-post-thumb">
      <img
        :src="require(`@/assets/images/business-post/${datas.image}`)"
        :alt="datas.titulo"
      />
    </div>
    <div class="bussiness-post-content">
      <h3 class="title">
        <router-link to="/posts/postOne">{{ datas.title }}</router-link>
      </h3>
      <div class="meta-date-link">
        <span>{{ datas.category.create_At }}</span>
        <ul>
          <li>
            <a href="#"><i class="fal fa-bookmark"></i></a>
          </li>
          <li>
            <a href="#"><i class="fas fa-share"></i></a>
          </li>
        </ul>
      </div>
      <p>
        {{ datas.article }}
      </p>
      <a href="#"
        >LEARN MORE <img src="@/assets/images/arrow-2.svg" alt=""
      /></a>
    </div>
  </div>
  <div v-else-if="stype === 'col'" class="trending-news-item">
      <div class="trending-news-thumb">
        <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
            <img 
                v-if="datas.imagenURLMd2"
                :src="datas.imagenURLMd2"
                :alt="datas.titulo" 
            />
        </router-link>

        <div v-if="datas.action === 'trending'" class="icon">
          <a href="#"><i class="fas fa-bolt"></i></a>
        </div>
      </div>
      <div class="trending-news-content">
        <div class="post-meta">
          <div class="meta-categories">
            <a href="#">{{ datas.firstCategoria.titulo }}</a>
          </div>
          <div class="meta-date">
              <span>{{formatDate(datas.fecha)}}</span>
          </div>
        </div>
        <h3 class="title">
            <router-link :to="{ name: 'PostThree', params: { slug: datas.slug} }">
                {{ datas.titulo }}
            </router-link>
        </h3>
        <p v-html="datas.contenido.substring(0,94)+'...'"></p>
      </div>
  </div>

  <div v-else-if="stype === 'categoria'" class="trending-news-item d-flex justify-content-center">
    <div class="col-lg-6 col-md-6 d-flex flex-column align-items-center">
      <div class="trending-news-thumb">
        <router-link :to="{ name: 'Features', params: { slug: datas.slug} }">
            <img 
                v-if="datas.imagenURL"
                :src="datas.imagenURL"
                :alt="datas.titulo" 
          />

        </router-link>

      </div>
      <div class="trending-news-content d-flex flex-column align-items-center">
        <div class="post-meta">
          <div class="meta-categories">
            <router-link :to="{ name: 'Features', params: { slug: datas.slug} }">
                {{ datas.titulo }}
            </router-link>
          </div>
        </div>
        <p v-if="datas.descripcion" class="line-clumb">{{ datas.descripcion }}</p>
      </div>
    </div>
  </div>

  <div v-else-if="stype === 'col-style-2'" class="finance-item">
    <div class="finance-thumb">
      <img
        :src="require(`@/assets/images/finance/${datas.image}`)"
        :alt="datas.titulo"
      />
      <div class="finance-date">
        <span>{{ datas.category.name }}</span>
      </div>
    </div>
    <div class="finance-content">
      <h3 class="title">
        <router-link to="/posts/postOne">{{ datas.title }}</router-link>
      </h3>
      <p class="line-clumb">
        {{ datas.article }}
      </p>
      <ul>
        <li>
          <a href="#"><i class="far fa-eye"></i> 53,346</a>
        </li>
        <li>
          <a href="#"><i class="far fa-heart"></i> 53,346</a>
        </li>
        <li>
          <a href="#"><i class="fas fa-share"></i> 53,346</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    datas: {
      type: Object,
    },
    readMore: {
      type: Boolean,
      default: false,
    },
    verOtrasCategorias: {
      type: Boolean,
      default: false,
    },
    stype: {
      type: String,
    },
  },

  setup() {
    const formatDate = (item) =>{
        moment.locale('es')
        let date = moment(item).format("MMMM D, YYYY");
        return date
	}
    return {
      formatDate,

    }
  }
};
</script>

<style></style>
