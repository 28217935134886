<template>
    <form @submit.prevent="onSubmit">
        <h3 class="title">
            Buzón Ciudadano
        </h3>
        <div v-if="!finalizado" class="row">
        <div class="col-md-12">
            <div class="input-box">
                <input type="text" v-model="tema" name="tema" placeholder="Tema" required />
            </div>
        </div>
        <div class="col-md-12">
            <div class="input-box">
                <textarea type="text" v-model="contenido" name="contenido" placeholder="Información" required ></textarea>
            </div>
        </div>
        <div class="col-md-12">
            <fieldset>Datos Informativos</fieldset>
        </div>
        <div class="col-md-6">
            <div class="input-box">
            <input type="text" v-model="nombre" name="nombre" placeholder="Nombre" required />
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-box">
            <input type="text" v-model="apellido" name="apellido" placeholder="Apellido" required />
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-box">
            <input type="email" v-model="email" name="email" placeholder="Correo Electrónico" required />
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-box">
            <input type="text" v-model="telefono" name="telefono" placeholder="Teléfono" required />
            </div>
        </div>
        <div class="col-lg-12">
            <div class="input-box">
                <button class="main-btn">ENVIAR</button>
            </div>
        </div>
        </div>
        <div v-else>
            <h2>
                Gracias por tu aporte.
            </h2>
        </div>
    </form>        
</template>

<script>
import { http } from "../../axios";

export default {
    data: () => ({
        tema: null,
        contenido: null,
        nombre: null,
        apellido: null,
        email: null,
        telefono: null,
        errorMessage: null,
        finalizado: false
    }),
    methods:{

        onSubmit(){
            
            http
            .post("auth/buzon", {
                tema: this.tema,
                contenido: this.contenido,
                nombre: this.nombre,
                apellido: this.apellido,
                telefono: this.telefono,
                email: this.email,
            })
            .then((response) => {
                console.log('response',response)

                if(response.data.status){
                    this.finalizado = true;
                }else{
                    this.errorMessage = response.data.message;
                }
            })
            .catch((error) => {
                console.log(error);
                this.errorMessage = error;
                this.loadingRecintos = false;
            });
        }
    }
};
</script>

<style></style>
