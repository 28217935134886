<template>
    <div>
      <info />
      <Form />
    </div>
  </template>
  
  <script>
  import Form from "../Contacts/Form.vue";
  import Info from "../Contacts/Info.vue";
  export default {
    components: { Info, Form},
    data: () => ({
      sidebar: false,
    }),
    created() {
      document.addEventListener("scroll", this.topToBottom);
    },
    methods: {
      toggleSidebar() {
        this.sidebar = !this.sidebar;
      },
      topToBottom() {
        const result = document.querySelector(".go-top");
        if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
        ) {
          result.classList.add("active");
        } else {
          result.classList.remove("active");
        }
      },
    },
  };
  </script>
  
  <style></style>
  