<template>
  <div class="contact-info-area pt-70 pb-90">
    <div class="container">
        <div class="post-content">
            <h3 class="title">
                Anuncia con Nosotros
            </h3>
        </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">Regístrate</h3>
            <span><i class="fas fa-map-marker-alt"></i> Primero crea una cuenta:</span>
            <ul>
              <li>Utiliza tu email</li>
              <li>o Gmail, Facebook</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">Personaliza</h3>
            <span><i class="fas fa-envelope"></i> Configura tu anuncio:</span>
            <ul>
              <li>Texto (Clasificado)</li>
              <li>o Imagen</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">Anuncia Gratis</h3>
            <span><i class="fas fa-phone"></i> Publica tu anuncio:</span>
            <ul>
              <li># días publicado</li>
              <li>secciones que se visualizan</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
