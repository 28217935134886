<template>
  <div class="newsletter-box mt-45">
    <h5 class="title">Suscribirse</h5>
    <p>
        Suscríbite para conocer mas sobre laveci
    </p>
    <form action="#">
      <div class="input-box">
        <input type="text" placeholder="Ingresa tu email" />
        <button type="button">Suscribirse</button>
      </div>
    </form>
    <!-- <span>We hate spam as much as you do</span> -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
