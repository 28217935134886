<template>
    <div>
          <section class="contact-area pt-90 pb-90">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="contact-box">
                            <Buzon />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    
  </template>
  
  <script>
  import Buzon from "../Contacts/Buzon.vue";
  
  export default {
    components: { Buzon},
    data: () => ({
      sidebar: false,
    }),
    created() {
      document.addEventListener("scroll", this.topToBottom);
    },
    methods: {
      toggleSidebar() {
        this.sidebar = !this.sidebar;
      },
      topToBottom() {
        const result = document.querySelector(".go-top");
        if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
        ) {
          result.classList.add("active");
        } else {
          result.classList.remove("active");
        }
      },
    },
  };
  </script>
  
  <style></style>
  