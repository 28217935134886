import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Dark from '../views/Dark'
import HomeTwo from '../views/HomeTwo'
import HomeThree from '../views/HomeThree'
import Contact from '../views/Contact.vue'
import About from '../views/About.vue'
import Archive from '../views/Archive.vue'
import fourOfour from '../components/404.vue'
import PostOne from '../components/Posts/PostOne/index.vue'
import PostTwo from '../components/Posts/PostTwo/index.vue'
import PostThree from '../components/Posts/PostThree/index'
import Business from '../components/category/Buisness.vue'
import Entertainment from '../components/category/Entertainment.vue'
import Features from '../components/category/Feature.vue'
import Sports from '../components/category/Sports.vue'
import Trending from '../components/category/Trending.vue'
import Encuesta from '../components/Encuestas/Encuesta.vue'
import Encuestas from '../components/Encuestas/Encuestas.vue'
import Resultados from '../components/Encuestas/Resultados.vue'
import AnunciaConNosotros from '../components/About/AnunciaConNosotros.vue'
import CodigoEtica from '../components/About/CodigoEtica.vue'
import BuzonCiudadano from '../components/About/BuzonCiudadano.vue'
import TerminosCondiciones from '../components/About/TerminosCondiciones.vue'
import PoliticaDePrivacidad from '../components/About/PoliticaDePrivacidad.vue'
import SignupStyleTwo from '../components/Utility/Signup/SignupStyleTwo.vue'


Vue.use(VueRouter)

import {store} from '../store/index'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/dark',
    name: 'Dark',
    component: Dark
  },
  {
    path: '/home-two',
    name: 'HomeTwo',
    component: HomeTwo
  },
  {
    path: '/home-three',
    name: 'HomeThree',
    component: HomeThree
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/noticias',
    name: 'Archive',
    component: Archive
  },
  {
    path: '/404',
    name: '404',
    component: fourOfour
  },
  {
    path: '/posts/postOne',
    name: 'PostOne',
    component: PostOne
  },
  {
    path: '/posts/posttwo',
    name: 'PostTwo',
    component: PostTwo
  },
  {
    path: '/noticia/:slug',
    name: 'PostThree',
    component: PostThree
  },
  {
    path: '/business',
    name: 'Business',
    component: Business
  },
  {
    path: '/categorias',
    name: 'Entertainment',
    component: Entertainment
  },
  {
    path: '/categoria/:slug',
    name: 'Features',
    component: Features
  },
  {
    path: '/departamentos/:slug',
    name: 'Sports',
    component: Sports
  },
  {
    path: '/trending',
    name: 'Trending',
    component: Trending
  },
  {
    path: '/encuestas',
    name: 'Encuestas',
    component: Encuestas
  },
  {
    path: '/encuesta/:id',
    name: 'Encuesta',
    component: Encuesta
  },
  {
    path: '/encuesta/:id/resultados',
    name: 'Resultados',
    component: Resultados
  },
  {
    path: '/terminos-y-condiciones',
    name: 'TerminosCondiciones',
    component: TerminosCondiciones
  },
  {
    path: '/politica-de-privacidad',
    name: 'PoliticaDePrivacidad',
    component: PoliticaDePrivacidad
  },
  {
    path: '/anuncia-con-nosotros',
    name: 'AnunciaConNosotros',
    component: AnunciaConNosotros
  },
  {
    path: '/codigoetica',
    name: 'CodigoEtica',
    component: CodigoEtica
  },
  {
    path: '/buzonciudadano',
    name: 'BuzonCiudadano',
    component: BuzonCiudadano
  },
  {
    path: '/registro',
    name: 'SignupStyleTwo',
    component: SignupStyleTwo
    },
    {
        path: '*',
        name: '404N',
        component: fourOfour
    },
]

const router = new VueRouter({
  mode: 'history',
    //base: process.env.BASE_URL,
  base: '/',
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },

})

router.beforeEach((to, from,next) => {
    console.log(to, );
    console.log(from);
    store.dispatch('hideLoader', true)
    next()
});

router.afterEach(() => {
    setTimeout( function(){
        store.dispatch('hideLoader', false)
    },900);
});

export default router
