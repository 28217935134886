<template>
    <div class="header-topbar">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8">
            <div class="topbar-trending">
              <span style="color: white; font-weight: 400;">Anuncios:</span>
              <!-- <div class="trending-slider">
                <span
                  @click="prev"
                  class="prev slick-arrow"
                  style="display: block"
                  ><i class="fal fa-angle-left text-white font-weight-bold"></i
                ></span> -->
         
                <!-- <slider :settings="settings" ref="carousel"> -->

                    <!--====== FOOTER PUBLICIDAD START ======-->
                    <anuncios-banner :tipo-anuncio="'w5'" :tipo="'slider'"/>
                    <!--====== FOOTER PUBLICIDAD ENDS ======-->

                  <!-- <template v-for="(data, index) in news">
                    <div :key="index" class="trending-item">
                      <p class="text-white font-weight-light">
                        {{ data.news }}
                      </p>
                    </div>
                  </template> -->
                <!-- </slider> -->
                <!-- <span
                  @click="next"
                  class="next slick-arrow"
                  style="display: block;"
                  ><i class="fal fa-angle-right text-white font-weight-bold"></i
                ></span>
              </div> -->
            </div>
          </div>
          <div class="col-lg-4">
            <div class="topbar-social d-flex align-items-center">
              <p class="text-white font-weight-light">{{date}}</p>
              <div class="social">
                <ul>
                  <li>
                    <a :href="$store.state.configs.facebook_url"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <!-- <li>
                    <a :href="$store.state.configs.facebook_url"><i class="fab fa-youtube"></i></a>
                  </li> -->
                  <li>
                    <a :href="$store.state.configs.instagram_url"><i class="fab fa-instagram"></i></a>
                  </li>
                  <li>
                    <a :href="$store.state.configs.twitter_url"><i class="fab fa-whatsapp"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
//   import Slider from "../../Helpers/Slider.vue";
  import moment from 'moment';
  import AnunciosBanner from "../Anuncios/AnunciosBanner.vue";


  export default {
    components: {AnunciosBanner },
    data: () => ({
      date: null,
      settings: {
        dots: false,
        arrows: false,
        autoplay: false,
      },
    
    }),
  
    created() {
      moment.locale('es')
      this.date = moment().format("D MMMM YYYY, h:mm:ss a");
    },

  };
  </script>
  
  <style>
  </style>