<template>
    <div>

      <!--====== POST PART START ======-->  
      <post-part-home-one v-if="articulos.length"  :articulos="articulos" />
        
      <!--====== POST PART ENDS ======-->
  
      <!--====== POST GALLERY PART START ======-->
      <post-gallery  :categorias-destacadas="categoriasDestacadas" />
  
      <!--====== POST GALLERY PART ENDS ======-->
  
      <!--====== FEATURE PART START ======-->
      <features v-if="ultimosArticulos.length"  :ultimosArticulos="ultimosArticulos"/>
  
      <!--====== FEATURE PART ENDS ======-->
  
      <!--====== TRENDING NEWS PART START ======-->
      <trending-news 
        v-if="categoriaDestacadaUno && categoriaDestacadaDos && categoriaDestacadaTres" 
            :categoria-destacada-uno="categoriaDestacadaUno" 
            :categoria-destacada-dos="categoriaDestacadaDos" 
            :categoria-destacada-tres="categoriaDestacadaTres" 
            :articulos-vistos="articulosVistos"
       />

      <!--====== TRENDING NEWS PART ENDS ======-->
  
      <!--====== SINGLE PLAY POST PART START ======-->
      <!-- <single-play-post /> -->

    </div>
  </template>
  <script>
  import PostPartHomeOne from "./PostPartHomeOne.vue";
  import PostGallery from "./PostGallery.vue";
  import Features from "./Features.vue";
  import TrendingNews from "./TrendingNews.vue";
 
//   import SinglePlayPost from "./SinglePlayPost.vue";
  
  import { http } from "../../axios"
  
  export default {
    components: {
      PostPartHomeOne,
      PostGallery,
      Features,
      TrendingNews,
    //   SinglePlayPost,
    },
    data: () => ({
      articulos: [],
      categoriasDestacadas: [],
      ultimosArticulos: [],
      categoriaDestacadaUno: {},
      categoriaDestacadaDos: {},
      categoriaDestacadaTres: {},
      articulosVistos: [],

  
    }),

    created() {
   
      document.addEventListener("scroll", this.topToBottom);
      this.fetchArticulos();
      this.fetchCategoriasDestacadas();

    },

   
    methods: {
      toggleSidebar() {
        this.sidebar = !this.sidebar;
      },
      topToBottom() {
        const result = document.querySelector(".go-top");
        if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
        ) {
          result.classList.add("active");
        } else {
          result.classList.remove("active");
        }
      },
  
      fetchArticulos(){
          // this.loadingRecintos = false
          http
          .get("web/articulos",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.articulos = response.data.items;
              this.ultimosArticulos = response.data.ultimos_articulos;
              this.articulosVistos = response.data.articulos_mas_vistos;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

      fetchCategoriasDestacadas(){
          http
          .get("web/categorias-destacadas",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.categoriasDestacadas = response.data.items;
              this.categoriaDestacadaUno = response.data.categoria_destacada_uno;
              this.categoriaDestacadaDos = response.data.categoria_destacada_dos;
              this.categoriaDestacadaTres = response.data.categoria_destacada_tres;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

    },
    
  };
  </script>
  
  <style scoped>
  /* play post */
  .single-play-post-area .single-play-box {
    padding: 23px 12px !important;
  }

  .no_mostrar{
    display: none;
  }
  </style>
  