<template>
    <form action="#">
        <h3 class="title">
            Ya tienes una cuenta?
        </h3>
        <form action="#">
            <div class="row">
    
                <div class="col-lg-12">
                    <div class="input-box">
                        <a href="https://admin.laveci.com" class="main-btn">INICIAR SESIÓN</a>
                    </div>
                </div>
            </div>
        </form>
    </form>        
</template>

<script>

export default {
  
};
</script>

<style></style>
