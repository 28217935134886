<template>
    <div class="count-down container-sm">
        <div class="timer">
            <h2 class="days">{{ days }}</h2>
            <small>Días</small>
        </div>
        <div class="timer">
            <h2 class="hours">{{ hours}}</h2>
            <small>Horas</small>
        </div>
        <div class="timer">
            <h2 class="minutes">{{minutes}}</h2>
            <small>Minutos</small>
        </div>
        <div class="timer">
            <h2 class="seconds">{{seconds}}</h2>
            <small>Segundos</small>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        inicio:{
            type: String,
            required: true
        },
        fecha:{
            type: String,
            required: true
        },
        finalizada:{
            type: Boolean,
            required: true
        }
    },
    data(){
        return {
            remainingTime:0,
            timer:null
        }
    },
    mounted(){
        // get the current time
        const currentTime = new Date().getTime();

        let f = new Date(this.fecha);

        if(this.finalizada){
            let i = new Date(this.inicio);
            // calculate the remaining time 
            this.remainingTime = f.getTime() - i.getTime();

        }else{
            // calculate the remaining time 
            this.remainingTime = f.getTime() - currentTime;
            
            
            let mv = this;
            this.timer = setInterval(function(){
                // get the current time
                const currentTime = new Date().getTime();
    
                let f = new Date(mv.fecha);
                // calculate the remaining time 
                mv.remainingTime = f.getTime() - currentTime;
    
                if(mv.remainingTime <0){
                    clearInterval(mv.timer)
                }
            },1000);

        }

    },
    computed:{
        days(){
            return Math.floor(this.remainingTime / 1000 / 60 / 60 / 24);
        },
        hours(){
            return Math.floor(this.remainingTime / 1000 / 60 / 60 ) % 24;
        },
        minutes(){
            return Math.floor(this.remainingTime / 1000 / 60 ) % 60;
        },
        seconds(){
            return Math.floor(this.remainingTime / 1000 ) % 60;
        },
    }
}
</script>
<style >
.count-down{
    display: flex;
    justify-content: space-around;
}
.count-down .timer{
    text-align: center;
    display: flex;
    flex-direction: column;
    border: 2px dotted black;
    border-radius: 10px;
    width: 75px;
}
.count-down .timer h2{
    padding: 0;
    margin: 0;
}
</style>