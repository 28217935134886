<template>
    <div>

        <section class="post-layout-1-area post-layout-2-area pb-80">
            <div class="container">
                <div class="row justify-content-center" v-if="encuesta">
                    <div class="col-lg-8">
                        <div class="post-layout-top-content post-layout-top-content-2">
                            <!-- <div class="thumb">
                                <img src="@/assets/images/post-thumb-5.png" alt="" />
                            </div> -->
                            <div class="post-content">
                                <h3 class="title">
                                    {{ encuesta.nombre }}
                                </h3>
                             </div>
                            <div class="post-text mt-30">
                                <p>
                                    {{ encuesta.descripcion }}
                                </p>
                                <hr>
                                <div class="row">
                                    <div :class="seleccionadoId == opcion.id ? 'opcion-seleccionada': ''" class="col-md-3 border rounded-sm opcion-encuesta" v-for="opcion in encuesta.opciones" :key="opcion.id">
                                        <div class="" @click="seleccionarOpcion(opcion)">
                                            <img :src="opcion.imagenURL" :alt="opcion.nombre">
                                            <h5 class="text-center">{{ opcion.nombre }}</h5>
                                            <h6 class="text-center">{{ opcion.descripcion }}</h6>
                                            <p class="text-center">{{ opcion.extra }}</p>

                                        </div>
                                    </div>
                                </div>
                                <div  v-if="!finalizado" class="mt-4 text-center" id="registrar-voto">
                                    <p v-if="seleccionado">Opción Seleccionada: <strong>{{ seleccionado.nombre }} </strong></p>
                                    <button @click="registrarVoto" class="main-btn">REGISTRAR VOTO</button>
                                </div>
                                <div v-else class="text-center mt-4">
                                    <p class="font-weight-bold text-uppercase  h3">Gracias por tu colaboración...</p>
                                </div>
                                <div class="mt-10 border-top">
                                    <div class="text-center font-italic my-1">
                                        {{ encuesta.activa ? 'Esta encuesta finaliza en' :  'Duración de la encuesta' }}
                                    </div>
                                    <countdown :finalizada="!encuesta.activa" :inicio="encuesta.inicio" :fecha="encuesta.fin"></countdown>
                                    <p class="text-center font-italic my-1">
                                        <router-link :to="{ name: 'Resultados', params: { id: encuesta.id} }">
                                            Ver resultados ({{ encuesta.respuestas_count }} votos)
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>
<script>
import { http } from "../../axios";

import Countdown from './Countdown';

export default {
    components: {
        Countdown
    },
    data: () => ({
        encuesta: null,
        seleccionado: null,
        finalizado: false
    }),

    created() {
        document.addEventListener("scroll", this.topToBottom);
        this.fetchEncuesta();
    },
    computed:{
        seleccionadoId(){
            return this.seleccionado ?  this.seleccionado.id : 0
        }
    },

    methods: {
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
        topToBottom() {
            const result = document.querySelector(".go-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.classList.add("active");
            } else {
                result.classList.remove("active");
            }
        },

        seleccionarOpcion(opcion){
            this.seleccionado = opcion;
            let votarArea = document.getElementById('registrar-voto');
            const rootEle = document.documentElement
            rootEle.scrollTo({
                top: votarArea.offsetTop,
                behavior: 'smooth',
            })
        },

        fetchEncuesta() {
            // this.loadingRecintos = false
            http
                .get("web/encuesta/"+this.$route.params.id, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    this.encuesta = response.data.items;
                    this.finalizado = !this.encuesta.activa
                })
                .catch((error) => {
                    console.log(error);
                    this.errorMessage = error;
                    this.loadingRecintos = false;
                });
        },
        registrarVoto(){
            if(this.seleccionado == null){
                alert('Debe seleccionar una opción');
            }else{
                http
                .post("web/encuesta/"+this.$route.params.id, {
                    opcion: this.seleccionado.id
                })
                .then((response) => {
                    console.log('response',response)

                    if(response.data.status){
                        this.finalizado = true;
                        if(this.encuesta.redireccionar){
                            window.location = this.encuesta.url_redireccion
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.errorMessage = error;
                    this.loadingRecintos = false;
                });
            }
        }
    },
};
</script>
  
<style scoped>
/* play post */
.single-play-post-area .single-play-box {
    padding: 23px 12px !important;
}

.no_mostrar {
    display: none;
}
.opcion-encuesta{
    cursor: pointer!important;
}
.opcion-encuesta:hover{
    background-color: #ef9f1e;
}

.opcion-seleccionada{
    background-color: #f41211 !important;
}
</style>
  