<template>

    <div v-if="tipoAnuncio == 'w0'" class="col-lg-8">
        <div v-if="anuncios" class="header-centerbar-ad">
            <img class="cursor-pointer" :src="anuncios.imagenOriginalURL" :alt="anuncios.contenido"/>
        </div>
        <div v-else class="header-centerbar-ad">
            <img :src="require(`@/assets/images/anuncios/banner1-header.jpg`)" alt=""/>
        </div>
    </div>

     

    <div v-else-if="tipoAnuncio == 'w1'" class="margin-w1">
        <div v-if="anuncios">
            <img class="cursor-pointer" :src="anuncios.imagenOriginalURL" :alt="anuncios.contenido" @click="goToAdd(anuncios)"/>
        </div>

        <div v-else>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <img  
                            :src="require(`@/assets/images/anuncios/banner1-footer.jpg`)"
                            style="width: 100%; height: 100%"
                        />
                    </div>
                </div>
            </div>
        </div>

    </div>


    <div v-else-if="tipoAnuncio == 'w2'" :class="vista == '/' ? 'control-margin' : ''">
        <img
          v-if="anuncios"
          :src="anuncios.imagenOriginalURL"
          :alt="anuncios.contenido"
          style="width: 100%; height: 100%"
          @click="goToAdd(anuncios)"
          class="cursor-pointer"
        />
        <img
          v-else
          :src="require(`@/assets/images/anuncios/banner1-secundario.jpg`)"
          alt=""
          style="width: 100%; height: 100%"
        />
    </div>



    <div v-else-if="tipoAnuncio == 'w3'">
        <img
          v-if="anuncios"
          :src="anuncios.imagenOriginalURL"
          :alt="anuncios.contenido"
          style="width: 100%; height: 100%"
          @click="goToAdd(anuncios)"
          class="cursor-pointer"
        />
        <img
          v-else
          :src="require(`@/assets/images/anuncios/banner1-principal.jpg`)"
          alt=""
          style="width: 100%; height: 100%"
        />
    </div>


    <div v-else-if="(tipoAnuncio == 'w4')">
        <img
          v-if="anuncios"
          :src="anuncios.imagenOriginalURL"
          :alt="anuncios.contenido"
          style="width: 100%; height: 100%"
          @click="goToAdd(anuncios)"
          class="cursor-pointer"
        />
        <img v-else :src="require(`@/assets/images/anuncios/banner1-header.jpg`)" alt=""/>
    </div>

    <div class="trending-slider" v-else-if="tipoAnuncio == 'w5' && anuncios">
        <div v-if="anuncios.length">
            <slider :settings="settings" ref="carousel">
                <template v-for="(data, index) in anuncios">
                    <div :key="index" class="trending-item cursor-pointer"  @click="goToAdd(data)" >
                        <p class="text-white font-weight-light">
                        {{ data.contenido }}
                        </p>
                    </div>
                </template>
            </slider>
        </div>
        <div v-else>
            <slider :settings="settings" ref="carousel"> -->
                <template v-for="(data, index) in news">
                    <div :key="index" class="trending-item">
                      <p class="text-white font-weight-light">
                        {{ data.news }}
                      </p>
                    </div>
                  </template>
            </slider>
        </div>

        <span
            @click="prev"
            class="prev slick-arrow"
            style="display: block"
            ><i class="fal fa-angle-left text-white font-weight-bold"></i>
        </span>

        <span
            @click="next"
            class="next slick-arrow"
            style="display: block;"
            ><i class="fal fa-angle-right text-white font-weight-bold"></i>
        </span>
     </div>
   

 
</template>

<script>
    import Slider from "../../Helpers/Slider.vue";
    import { http } from "../../../axios"
    import json from "../../Data/TrandingHeaderOneTopBar";

    
    export default {
        components: { Slider },
        props: {
            tipoAnuncio: {
                type: String,
            },
            tipo: {
                type: String,
                default: 'banner',
            },
        },
        data: () => ({
            //anuncios: [],
            consulta: 1,
            settings: {
                dots: false,
                arrows: false,
                autoplay: false,
            },
            news: json.datas,
            vista: null,

            
        }),

        computed:{
            anuncios(){
                console.log('COMPUTED')
                let a = this.$store.getters.getAnuncioByTipo(this.tipoAnuncio, this.tipo)
                console.log('anuncio computed ',this.tipoAnuncio, this.tipo, a)
                return a ? a : null;
            },
        },
        created() {
            this.vista = this.$route.path;
            //this.fetchAnuncios();
            // setInterval(() => {
            //     this.fetchAnuncios();
            // }, "20000");
            // this.$store.dispatch('loadAnuncios',{
            //     tipoAnuncio:this.tipoAnuncio,
            //     tipo:this.tipo
            // })
        },

        watch: {
            '$route.path': {
                handler: function() {
                    console.log("menu",this.$route.path);
                    this.vista = this.$route.path;
                    console.log("menu",this.vista);
                },
                // deep: true,
                // immediate: true
                }
        },

        
        methods: {
            fetchAnuncios(){
                // this.loadingRecintos = false
                http
                .get("web/anuncios",{
                    params:{ codigo:  this.tipoAnuncio , tipo: this.tipo  },
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then( response => {
                    this.anuncios = response.data.items;
                    // console.log("ewdii",this.anuncios);
                })
                .catch((error) => {
                    console.log(error);
                    this.errorMessage = error
                    this.loadingRecintos = false
                });
            },

            goToAdd(add){
                if(add.anuncio.url){
                    window.location = add.anuncio.url
                }
            },

            next() {
                this.$refs.carousel.next();
             },
            prev() {
                this.$refs.carousel.prev();
            },

        },
        mounted() {
            this.$store.dispatch('loadAnuncios',{
                tipoAnuncio:this.tipoAnuncio,
                tipo:this.tipo
            })
        },
        unmounted() {
            this.$store.dispatch('removeAnuncios',{
                tipoAnuncio:this.tipoAnuncio,
                tipo:this.tipo
            })
            
        },
    }
</script>
