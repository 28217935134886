<template>
  <section class="contact-area pb-90">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="contact-box">
            <Login />
            <hr>
            <hr>
            <hr>
            <Register />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="contact-sidebar">
            <FromCount />
            <signup-style-one />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FromCount from "../Utility/FollowCount/index";
import SignupStyleOne from "../Utility/Signup/SignupStyleOne.vue";
import Login from "./Login";
import Register from "./Register";
export default {
  components: { FromCount, SignupStyleOne, Login, Register },
};
</script>

<style></style>
