<template>
  <div>
    <!-- <category-layout> -->
      <section class="about-item-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="about-author-content pt-15">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item" ><router-link :to="{ name: 'Home'}">Inicio</router-link></li>
                    <li class="breadcrumb-item active"><a>Categorías</a></li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="about-tab-btn mt-40">
                <div class="archive-btn">
                  <ul>
                    <li>¡Explora todas las categorías 😎!</li>
                  </ul>
                </div>
                <div v-for="categoria in categorias" :key="categoria.id" class="about-post-items">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="bussiness-post-item mb-40">
                        <div class="bussiness-post-thumb">
                          <router-link :to="{ name: 'Features', params: { slug: categoria.slug} }">
                            <img
                              :src="categoria.imagenURL"
                              alt="business"
                              width="20%"
                            />
                          </router-link>
                        </div>
                        <div class="bussiness-post-content">
                          <h3 class="title">
                            <router-link :to="{ name: 'Features', params: { slug: categoria.slug} }">
                              {{ categoria.titulo }}
                            </router-link>
                          </h3>
                          <!-- <div class="meta-date-link">
                            <span>April 26, 2020</span>
                            <ul>
                              <li>
                                <a href="#"><i class="fal fa-bookmark"></i></a>
                              </li>
                              <li>
                                <a href="#"><i class="fas fa-share"></i></a>
                              </li>
                            </ul>
                          </div> -->
                          <p v-if="categoria.subtitulo">
                            {{ categoria.subtitulo }}
                          </p>
                          <router-link :to="{ name: 'Features', params: { slug: categoria.slug} }">
                            Ver Noticias
                            <img src="@/assets/images/arrow-2.svg" alt=""/>
                          </router-link>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-lg-12">
                      <div class="bussiness-btn">
                        <a class="main-btn main-btn-2" href="#">See more</a>
                      </div>
                    </div> -->
                  </div>
                </div>

                <!-- paginación -->
                <div class="col-lg-12">
                    <div class="pagination-item">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li v-for="pagina in totalPaginas" :key=pagina class="page-item"  :class="paginaActual == pagina ? 'active' : ''" ><a @click="paginado(pagina)" class="page-link"> {{ pagina }}</a></li>
                                <!-- <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true"><i class="fas fa-caret-right"></i></span>
                                    </a>
                                </li> -->
                            </ul>
                        </nav>
                    </div>
                  </div>

              </div>
            </div>
            <div class="col-lg-4">
              <!-- <style-one :datas="posts.slice(1, 6)" :sharePost="false" /> -->
              <styleOne class="mt-40" role="sidebar"  :signup="true" :trendingShortPost="false" :trendingBigPost="false" :ad="true" :categorias-destacadas="categoriasDestacadas" :categoria-destacada-tres="categoriaDestacadaTres" :articulos-vistos="articulosVistos"/>
            </div>
          </div>
        </div>
      </section>
    <!-- </category-layout> -->
  </div>
</template>

<script>
// import CategoryLayout from "./CategoryLayout.vue";
import Posts from "../Data/TrendingHomeThree";
import StyleOne from "../Utility/Sidebar/StyleOne.vue";


import { http } from "../../axios"


export default {
  components: { StyleOne },
  data: () => ({
    posts: Posts.data,
    categorias: [],

    totalItems: null,
    paginaActual: null,
    totalPaginas: null,



    categoriasDestacadas: [],
    categoriaDestacadaTres: {},
    articulosVistos: [],

  }),
  created() {
   this.fetchCategorias();
   this.fetchCategoriaDestacadaTres();
   this.fetchCategoriasDestacadas();
   this.fetchArticulosMasVistos();

 },

  methods: {
    paginado (pagina) {
      if(pagina != this.paginaActual ){
        this.fetchCategorias(pagina);

      }
    },
    fetchCategorias(pagina){
        http
        .get("web/all-categorias",{
            params:{ page: pagina },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            this.categorias = response.data.items;
            
            this.totalItems = response.data.total;
            this.paginaActual = response.data.current_page;
            this.totalPaginas = response.data.last_page;
        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },

    fetchCategoriasDestacadas(){
        http
        .get("web/categorias-destacadas",{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            this.categoriasDestacadas = response.data.items;
        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },

    fetchCategoriaDestacadaTres(){
          http
          .get("web/categoria-destacada-tres",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.categoriaDestacadaTres = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

      fetchArticulosMasVistos(){
          http
          .get("web/articulos-mas-vistos",{
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then( response => {
              this.articulosVistos = response.data.items;
          })
          .catch((error) => {
              console.log(error);
              this.errorMessage = error
              this.loadingRecintos = false
          });
      },

  }
};
</script>

<style></style>
