<template>
  <div>
    <slot></slot>
    <!--====== LATEST NEWS PART START ======-->
    <our-latest-news v-if="otrasCategorias.length" :titulo="'Otras Categorías'" stype="categoria" :posts="otrasCategorias" />
    <!--====== LATEST NEWS PART ENDS ======-->
  
  </div>
</template>

<script>
import OurLatestNews from "../Utility/Common/OurLatestNews.vue";
import Posts from "../Data/About";

import { http } from "../../axios"

export default {
  components: { OurLatestNews },
  data: () => ({
    posts: Posts.data,
    otrasCategorias: [],

  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
    this.fetchOtrosCategorias();

  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },

    fetchOtrosCategorias(){
        http
        .get("web/otras-categorias",{
            params:{ slug: this.$route.params.slug},
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then( response => {
            this.otrasCategorias = response.data.items;
        })
        .catch((error) => {
            console.log(error);
            this.errorMessage = error
            this.loadingRecintos = false
        });
    },

   
  },
};
</script>

<style></style>
