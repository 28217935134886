<template>
    <div>
        <div class="about-author-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="about-author-content">
                            <h2 class="text-center">
                                Términos y Condiciones de Uso de www.laveci.com
                            </h2>
                            <div>
                                <p>
                                    1. Aceptación de Términos y Condiciones
    
                                    Al acceder y utilizar el sitio web www.laveci.com (en adelante "el Sitio"), aceptas cumplir y estar sujeto a estos Términos y Condiciones de Uso. Si no estás de acuerdo con estos términos, te recomendamos que no utilices el Sitio.

                                </p>
                                <p>
                                    2. Uso Apropiado
    
                                    El Sitio está destinado a ser utilizado con fines informativos y educativos. No debes utilizar el Sitio para actividades ilegales, inapropiadas o que violen los derechos de terceros.
                                </p>
                                <p>
                                    3. Propiedad Intelectual
    
                                    Todo el contenido, incluidos textos, logotipos y otros elementos en el Sitio, están protegidos por derechos de autor y otras leyes de propiedad intelectual. No puedes copiar, reproducir, distribuir ni utilizar el contenido sin permiso expreso.
                                </p>
                                <p>
                                    4. Privacidad
    
                                    Nuestra Política de Privacidad describe cómo recopilamos, almacenamos y utilizamos la información de los usuarios. Al utilizar el Sitio, aceptas los términos de nuestra Política de Privacidad.
                                </p>
                                <p>
                                    5. Enlaces a Terceros
    
                                    El Sitio podría contener enlaces a sitios web de terceros. No respaldamos ni tenemos control sobre el contenido de estos sitios. Tu uso de sitios de terceros está sujeto a los términos y condiciones de esos sitios.
                                </p>
                                <p>
                                    6. Limitación de Responsabilidad
    
                                    El uso del Sitio es bajo tu propio riesgo. No garantizamos la exactitud, integridad ni disponibilidad continua del contenido. No seremos responsables por daños directos, indirectos, incidentales o consecuentes que surjan del uso del Sitio.
                                </p>
                                <p>
                                    7. Modificaciones
    
                                    Nos reservamos el derecho de modificar, suspender o descontinuar el Sitio en cualquier momento sin previo aviso. También nos reservamos el derecho de actualizar estos Términos y Condiciones. Te recomendamos revisar periódicamente estos términos para estar al tanto de cualquier cambio.
                                </p>

                                <p>
                                    8. Ley Aplicable
    
                                    Estos Términos y Condiciones se rigen por las leyes de Ecuador. Cualquier disputa relacionada con el uso del Sitio se resolverá en los tribunales de Ambato - Ecuador.
                                </p>





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="go-top-area">
            <div class="go-top-wrap">
            <div class="go-top-btn-wrap">
                <a href="#" class="go-top go-top-btn d-block">
                <i class="fa fa-angle-double-up"></i>
                <i class="fa fa-angle-double-up"></i>
                </a>
            </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>

  export default {
    components: {

    },
    data: () => ({
      sidebar: false,
    }),
    created() {
      document.addEventListener("scroll", this.topToBottom);
    },
    methods: {
      toggleSidebar() {
        this.sidebar = !this.sidebar;
      },
      topToBottom() {
        const result = document.querySelector(".go-top");
        if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
        ) {
          result.classList.add("active");
        } else {
          result.classList.remove("active");
        }
      },
    },
  };
  </script>
  
  <style></style>
  