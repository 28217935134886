import Vue from 'vue'
import Vuex from 'vuex'

import { http } from "../axios"

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        videoPopup:false,
        buscadorModal:false,
        loader: true,
        configs:{},
        menuDepartamentos:[],
        menuDepartamentosDest: [],
        tiposAnuncios:[],
        anuncios:[],
    },
    getters: {
        getAnuncioByTipo: (state) => (tipoAnuncio, tipo) => {
            console.log('state.tiposAnuncios', state.tiposAnuncios)
            console.log('tipoAnuncio', tipoAnuncio)
            console.log('tipo', tipo)
            let index = state.tiposAnuncios.findIndex(ta => (ta.tipoAnuncio == tipoAnuncio && ta.tipo == tipo));
            console.log('index', index)

            return state.anuncios[index];
        }
    },
    mutations: {
        SET_VALUE_VIDEO:(state, payload)=> {
            console.log(payload)
            state.videoPopup = payload   
        },
        SET_VALUE_BUSCADOR:(state, payload)=> {
            console.log(payload)
            state.buscadorModal = payload   
        },
        SET_RELOAD_LOADER(state, value){
            state.loader = value;
            //console.log("SET_RELOAD_LOADER",state.loader);
        },
        SET_CONFIGS(state, value){
            state.configs = value;
            //console.log("SET_CONFIGS",state.configs);
        },
        SET_DEPARTAMENTOS(state, value){
            state.menuDepartamentos = value;
            //console.log("SET_DEPARTAMENTOS",state.menuDepartamentos);
        },
        SET_DEPARTAMENTOS_DESTACADOS(state, value){
            state.menuDepartamentosDest = value;
            //console.log("SET_DEPARTAMENTOS_DESTACADOS",state.menuDepartamentosDest);
        },
        ADD_TIPO_ANUNCIO(state, value){
            let find = state.tiposAnuncios.find(ta => ta.tipoAnuncio == value.tipoAnuncio && ta.tipo == value.tipo)
            if (!find) {
                state.tiposAnuncios.push(value);
            }
            console.log("ADD_TIPO_ANUNCIO", state.tiposAnuncios);
        },
        REMOVE_TIPO_ANUNCIO(state, value){
            let find = state.tiposAnuncios.findIndex(ta => ta.tipoAnuncio == value.tipoAnuncio && ta.tipo == value.tipo)
            if (find) {
                state.tiposAnuncios.splice(find,1);
            }
            console.log("REMOVE_TIPO_ANUNCIO", find, state.tiposAnuncios);
        },
        SET_ANUNCIOS(state, value) {
            state.anuncios = value;
            console.log("SET_ANUNCIOS", state.anuncios);
        },
    },
    actions: {
        toggleVideo({state,commit }) {
            commit('SET_VALUE_VIDEO',!state.videoPopup)
        },
        toggleBuscador({state,commit }) {
            commit('SET_VALUE_BUSCADOR',!state.buscadorModal)
        },
        hideLoader({commit},value) {
            console.log("hideLoader");
            commit('SET_RELOAD_LOADER', value)
        },
        loadConfigs({commit}){
            http
            .get("web/configs")
            .then( response => {
                //console.log('response configs',response)
                commit('SET_CONFIGS', response.data.items)
            })
            .catch((error) => {
                console.log(error);
            });
        },
        loadDepartamentos({commit}){
            http
            .get("web/departamentos-destacados")
            .then( response => {
                //console.log('response departamentos',response)
                commit('SET_DEPARTAMENTOS_DESTACADOS', response.data.items_destacados)
                commit('SET_DEPARTAMENTOS', response.data.items)
            })
            .catch((error) => {
                console.log(error);
            });
        },
        loadAnuncios({commit}, tipo){
            commit('ADD_TIPO_ANUNCIO',tipo)
        },
        removeAnuncios({commit}, tipo){
            commit('REMOVE_TIPO_ANUNCIO',tipo)
        },
        fetchAnuncios({state, commit}){
            http
            .post("web/all-anuncios",{tipos: state.tiposAnuncios})
            .then( response => {
                console.log('response anuncios',response)
                commit('SET_ANUNCIOS', response.data.items)
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }

})