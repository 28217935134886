var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"trending-news-area"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{class:[
            'section-title',
            _vm.darkClass && 'title' in _vm.darkClass ? _vm.darkClass.title : '',
          ]},[_c('router-link',{attrs:{"to":{ name: 'Features', params: { slug: _vm.categoriaDestacadaUno.slug} }}},[_c('h3',{staticClass:"title seccion-ultimas-categoria"},[_vm._v(_vm._s(_vm.categoriaDestacadaUno.titulo))])])],1),_c('div',{staticClass:"trending-news-slider position-relative"},[_c('span',{staticClass:"prev slick-arrow",staticStyle:{"display":"block"},on:{"click":_vm.trandingPrev}},[_c('i',{staticClass:"fal fa-angle-left"})]),(_vm.categoriaDestacadaUno.articulos)?_c('slider',{ref:"trendingSlider",attrs:{"settings":_vm.trendingSettings}},[_vm._l((_vm.categoriaDestacadaUno.articulos),function(data,index){return [_c('div',{key:index,staticClass:"px-2"},[_c('divide-card',{class:[
                    _vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : '',
                  ],attrs:{"stype":"col","datas":data}})],1)]})],2):_vm._e(),_c('span',{staticClass:"next slick-arrow",staticStyle:{"display":"block"},on:{"click":_vm.trandingNext}},[_c('i',{staticClass:"fal fa-angle-right"})])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{class:[
                'trending-news-post-items',
                _vm.darkClass && 'news_section' in _vm.darkClass
                  ? _vm.darkClass.news_section
                  : '',
              ]},[_vm._l((_vm.categoriaDestacadaDos.articulos),function(small,index){return [_c('row-card',{key:index,class:[
                    _vm.darkClass && 'news_item' in _vm.darkClass
                      ? _vm.darkClass.news_item
                      : '',
                  ],attrs:{"trending":true,"datas":small}})]})],2)]),_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{class:[
                'trending-news-post-items',
                _vm.darkClass && 'news_section' in _vm.darkClass
                  ? _vm.darkClass.news_section
                  : '',
              ]},[_vm._l((_vm.categoriaDestacadaTres.articulos),function(small,index){return [_c('row-card',{key:index,class:[
                    _vm.darkClass && 'news_item' in _vm.darkClass
                      ? _vm.darkClass.news_item
                      : '',
                  ],attrs:{"trending":true,"datas":small}})]})],2)])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"trending-right-sidebar"},[_c('style-one',{attrs:{"ad":true,"trendingShortPost":false,"trendingBigPost":false,"articulos-vistos":_vm.articulosVistos}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }