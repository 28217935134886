<template>
    <div>

        <section class="post-layout-1-area post-layout-2-area pb-80">
            <div class="container">
                <div class="row justify-content-center" v-if="encuesta">
                    <div class="col-lg-8">
                        <div class="post-layout-top-content post-layout-top-content-2">
                            <!-- <div class="thumb">
                                <img src="@/assets/images/post-thumb-5.png" alt="" />
                            </div> -->
                            <div class="post-content">
                                <h3 class="title">
                                    {{ encuesta.nombre }}
                                </h3>
                             </div>
                            <div class="post-text mt-30">
                                <h4 class="d-flex justify-content-between">
                                    <span>
                                        RESULTADOS
                                    </span>
                                    <small class="font-italic">
                                        {{ encuesta.respuestas_count }} votos
                                    </small>
                                </h4>
                                <hr>
                                <div class="row">
                                    <pie :encuesta="encuesta" />
                                </div>
                                <div class="mt-10 border-top">
                                    <div class="text-center font-italic my-1">
                                        Duración de la encuesta
                                    </div>
                                    <countdown :finalizada="true" :inicio="encuesta.inicio" :fecha="encuesta.fin"></countdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>
<script>
import { http } from "../../axios";

import Countdown from './Countdown';
import Pie from './graphs/Pie';

export default {
    components: {
        Countdown,
        Pie
    },
    data: () => ({
        encuesta: null,
        seleccionado: null,
        finalizado: false
    }),

    created() {
        document.addEventListener("scroll", this.topToBottom);
        this.fetchEncuesta();
    },
    computed:{
        seleccionadoId(){
            return this.seleccionado ?  this.seleccionado.id : 0
        }
    },

    methods: {
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
        topToBottom() {
            const result = document.querySelector(".go-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.classList.add("active");
            } else {
                result.classList.remove("active");
            }
        },

        seleccionarOpcion(opcion){
            this.seleccionado = opcion;
            let votarArea = document.getElementById('registrar-voto');
            const rootEle = document.documentElement
            rootEle.scrollTo({
                top: votarArea.offsetTop,
                behavior: 'smooth',
            })
        },

        fetchEncuesta() {
            // this.loadingRecintos = false
            http
                .get("web/encuesta/"+this.$route.params.id, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    this.encuesta = response.data.items;
                })
                .catch((error) => {
                    console.log(error);
                    this.errorMessage = error;
                    this.loadingRecintos = false;
                });
        },
        registrarVoto(){
            if(this.seleccionado == null){
                alert('Debe seleccionar una opción');
            }else{
                http
                .post("web/encuesta/"+this.$route.params.id, {
                    opcion: this.seleccionado.id
                })
                .then((response) => {
                    console.log('response',response)

                    if(response.data.status){
                        this.finalizado = true;
                        if(this.encuesta.redireccionar){
                            window.location = this.encuesta.url_redireccion
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.errorMessage = error;
                    this.loadingRecintos = false;
                });
            }
        }
    },
};
</script>
  
<style scoped>
/* play post */
.single-play-post-area .single-play-box {
    padding: 23px 12px !important;
}

.no_mostrar {
    display: none;
}
.opcion-encuesta{
    cursor: pointer!important;
}
.opcion-encuesta:hover{
    background-color: #ef9f1e;
}

.opcion-seleccionada{
    background-color: #f41211 !important;
}
</style>
  