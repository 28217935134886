<template>
  <section class="about-item-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="about-tab-btn mt-40">
              <div class="archive-btn">
                  <ul>
                      <li>¡Revisa todas las noticias 🤯!</li>
                      <!-- <li><i class="fal fa-calendar"></i></li> -->
                  </ul>
              </div>
           
              <div class="about-post-items">
                  <div class="row">
                      <div v-for="(data,index) in articulos" :key="index" class="col-lg-6 col-md-6">
                          <div class="trending-news-item mb-30">
                              <div class="trending-news-thumb">
                                <router-link :to="{ name: 'PostThree', params: { slug: data.slug} }">
                                    <img :src="data.imagenURLMd2" :alt="data.titulo">
                                </router-link>  
                              </div>
                              <div class="trending-news-content">
                                  <div class="post-meta">
                                      <div class="meta-categories">
                                        <router-link :to="{ name: 'Features', params: { slug: data.firstCategoria.slug} }">
                                            {{data.firstCategoria.titulo }}
                                        </router-link>
                                      </div>
                                      <div class="meta-date">
                                          <span>{{formatDate(data.fecha) }}</span>
                                      </div>
                                  </div>
                                  <router-link :to="{ name: 'PostThree', params: { slug: data.slug} }">
                                    <h3 class="title">{{data.titulo}}</h3>
                                  </router-link>
                                  <p v-html="data.contenido.substring(0,100)+'...'"></p>
                                  <!-- <p class="line-clumb" v-html="data.contenido"></p> -->
                              </div>
                          </div>
                      </div>
                      <!-- paginación -->
                      <div class="col-lg-12">
                          <div class="pagination-item">
                              <nav aria-label="Page navigation example">
                                  <ul class="pagination">
                                    
                                      <li v-for="pagina in totalPaginas" :key=pagina class="page-item"  :class="paginaActual == pagina ? 'active' : ''" ><a v-if="pagina <= 6" @click="paginado(pagina)" class="page-link"> {{ pagina }}</a></li>
                                      
                                      <!-- <li class="page-item">
                                          <a class="page-link" href="#" aria-label="Next">
                                              <span aria-hidden="true"><i class="fas fa-caret-right"></i></span>
                                          </a>
                                      </li> -->
                                  </ul>
                              </nav>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        
        <div class="col-lg-4">
          <styleOne class="mt-40" role="sidebar" :datas="aboutDatas" :signup="true" :trendingShortPost="false" :trendingBigPost="false" :ad="true" :categorias-destacadas="categoriasDestacadas" :categoria-destacada-tres="categoriaDestacadaTres" :articulos-vistos="articulosVistos"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import styleOne from "../Utility/Sidebar/StyleOne.vue";
import aboutDatas from "../Data/About";
import moment from 'moment';

export default {
  components: { styleOne},
  data: () => ({
    selected: "latest",
    aboutDatas: aboutDatas.data,

  }),
  methods: {
    selectTab(value) {
      this.selected = value;
    },

    paginado (pagina) {
      if(pagina != this.paginaActual ){
        this.$emit("fetch-all-articulos", pagina);
        
      }
    },

    
   
  },
  props: {
    articulos: {
      type: Array,
      required: false,
      default (){
          return [];
      },
    },
    totalArticulos: {
      type: Number,
      required: true,
    },

    paginaActual: {
      type: Number,
      required: true,
    },
    totalPaginas: {
      type: Number,
      required: true,
    },

    categoriasDestacadas: {
      type: Array,
      required: false,
      default (){
          return [];
      },
    },
    categoriaDestacadaTres: {
      type: Object,
      default: () => {},
    },

    articulosVistos: {
        type: Array,
        default: () => [],
    },

  },

  setup() {
    const formatDate = (item) =>{
        moment.locale('es')
        let date = moment(item).format("MMMM D, YYYY");
        return date
    }
    return {
      formatDate,

    }
  }


};
</script>

<style></style>
