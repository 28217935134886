<template>
    <div>
        <div class="about-author-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="about-author-content">
                            <h2 class="text-center">
                                Política de Privacidad de www.laveci.com
                            </h2>
                            <div>
                                <p>
                                    1. Información que Recopilamos

                                    <br>
                                    a. Información Personal: Podemos recopilar información personal que proporcionas voluntariamente, como nombre, dirección de correo electrónico y otra información de contacto.
                                    <br>
                                    b. Información de Uso: Recopilamos automáticamente información sobre tu interacción con el sitio, como direcciones IP, tipo de navegador, páginas visitadas y tiempo de acceso.
                                </p>
                                <p>
                                    2. Uso de la Información
    
                                    <br>
                                    a. Personalización: Utilizamos la información para personalizar tu experiencia en el sitio y para enviar contenido relevante.

                                    <br>
                                    b. Comunicación: Podemos utilizar tu información para enviarte comunicaciones relacionadas con el sitio, actualizaciones y noticias.

                                </p>
                                <p>
                                    3. Compartir de Información
                                    <br>
                                    a. Terceros de Confianza: Podemos compartir información con proveedores de servicios confiables que nos ayudan a operar el sitio.
                                    <br>
                                    b. Cumplimiento Legal: Revelaremos información cuando sea necesario para cumplir con la ley, regulaciones o procesos legales.

                                </p>
                                <p>
                                    4. Cookies y Tecnologías Similares
                                    <br>
                                    Utilizamos cookies y tecnologías similares para mejorar la experiencia del usuario, realizar análisis y personalizar el contenido.

                                </p>
                                <p>
                                    5. Seguridad de Datos
                                    <br>
                                    Implementamos medidas de seguridad para proteger la información contra acceso no autorizado, pérdida o alteración.

                                </p>
                                <p>
                                    6. Tus Derechos
                                    <br>
                                    Tienes derechos sobre tus datos personales, incluido el acceso, la rectificación y la eliminación de los datos. Puedes ejercer estos derechos poniéndote en contacto con nosotros.
                                </p>
                                <p>

                                    7. Enlaces a Terceros
                                    <br>
                                    El sitio puede contener enlaces a sitios de terceros. No nos hacemos responsables de las prácticas de privacidad de esos sitios.
                                </p>
                                <p>
                                    8. Cambios en la Política de Privacidad
                                    <br>
                                    Nos reservamos el derecho de actualizar nuestra política de privacidad. Te recomendamos revisarla periódicamente.

                                </p>
                                <p>

                                    9. Aceptación de la Política de Privacidad
                                    <br>
                                    Al utilizar el sitio, aceptas los términos de esta política de privacidad.
                                </p>
                                <p>
                                    10. Contacto
                                    <br>
                                    Si tienes preguntas sobre esta política de privacidad, puedes ponerte en contacto con nosotros laveci.info@gmail.com
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="go-top-area">
            <div class="go-top-wrap">
            <div class="go-top-btn-wrap">
                <a href="#" class="go-top go-top-btn d-block">
                <i class="fa fa-angle-double-up"></i>
                <i class="fa fa-angle-double-up"></i>
                </a>
            </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>

  export default {
    components: {

    },
    data: () => ({
      sidebar: false,
    }),
    created() {
      document.addEventListener("scroll", this.topToBottom);
    },
    methods: {
      toggleSidebar() {
        this.sidebar = !this.sidebar;
      },
      topToBottom() {
        const result = document.querySelector(".go-top");
        if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
        ) {
          result.classList.add("active");
        } else {
          result.classList.remove("active");
        }
      },
    },
  };
  </script>
  
  <style></style>
  